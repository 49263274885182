import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import {
  Section,
  Container,
  SectionTitle,
  SectionSubTitle,
  SectionImageBkg,
  ExploreMore,
  MarkdownContent,
  BreadCrumb,
} from "../components/Section";
import {
  Banner,
  BannerLeft,
  BannerRight,
  BannerTitle,
  BannerLead,
  BannerDesc,
} from "../components/Banner";
import { CheckIcon, PhoneIcon } from "../components/Icons";
import CategoryCarousel from "../components/CategoryCarousel";
import ColorPicker from "../components/ColorPicker";
import PrimaryButton from "../components/Button/PrimaryButton";
import RequestModal from "../components/RequestModal";
import ProductCard from "../components/ProductCard";
// import { getBreadcrumbSchema } from "../schema";

const Banners = styled(Banner)`
  @media (max-width: 991.98px) {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 64px;
  }
`;
const BannerfulLeft = styled(BannerLeft)`
  position: relative;
  height: 100%;

  padding: 40px 15px;
  @media (min-width: 992px) {
    padding: 120px 30px 40px 30px;
  }
  @media (min-width: 1200px) {
    padding: 140px 30px 40px 30px;
  }
  @media (min-width: 1640px) {
    padding: 160px 30px 40px calc(50vw - 790px);
  }
`;
const BannerfulRight = styled(BannerRight)`
  position: relative;
  background: #002765;
  text-align: center;
  padding: 0;
  height: 300px;
  @media (min-width: 567px) {
    height: 360px;
  }
  @media (min-width: 768px) {
    height: 400px;
  }
  @media (min-width: 992px) {
    height: 100%;
  }

  .gatsby-image-wrapper {
    display: block;
    height: 100%;
  }
`;
const SectionVectorImage = styled(SectionImageBkg)`
  background: #fff;
  bottom: 0;
  top: auto;
  right: 0;
  left: auto;
  overflow: hidden;

  height: calc(100% - 40px);
  border-radius: 40px 0 0 0;
  @media (min-width: 992px) {
    border-radius: 80px 0 0 0;
  }
  @media (min-width: 1200px) {
    border-radius: 140px 0 0 0;
    height: calc(100% - 80px);
  }
  @media (min-width: 1410px) {
    width: calc((100% + 1410px) / 2);
  }
  @media (min-width: 1600px) {
    border-radius: 200px 0 0 0;
    height: calc(100% - 120px);
  }
  > .gatsby-image-wrapper {
    position: absolute;
    right: -100px;
    top: 120px;
    width: auto;
    height: auto;
    opacity: 0.3;
    display: none;
    @media (min-width: 1200px) {
      display: inline-block;
    }
    @media (min-width: 1600px) {
      opacity: 1;
    }
  }
`;
const Sectionsize = styled(Section)`
  position: relative;
  .container {
    padding: 40px 30px;
    @media (min-width: 1200px) {
      padding: 60px 30px;
    }
    @media (min-width: 1600px) {
      padding: 80px 30px;
    }
  }
`;
const SectionTitles = styled(SectionTitle)`
  @media (max-width: 575.98px) {
    font-size: 30px;
    line-height: 40px;
  }
`;
const SectionFeaturesImage = styled(SectionImageBkg)`
  background-color: #161420;
  width: 75%;
  height: 100%;
  border-radius: 20px 0 0 50%;
  right: 0;
  left: auto;
`;
const Features = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;
const FeaturesLeft = styled.div`
  position: relative;
  height: 100%;

  padding: 40px 20px 0 20px;
  @media (min-width: 992px) {
    padding: 80px 30px;
  }
  @media (min-width: 1640px) {
    padding: 80px 0 80px calc(50vw - 790px);
  }
`;
const FeaturesRight = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 40px 0 40px 20px;
  @media (min-width: 992px) {
    padding: 40px 0 40px 30px;
  }
  @media (min-width: 1640px) {
    padding: 40px calc(40vw - 790px) 40px 30px;
  }
`;

const GridIcon = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;
const GridItemIcon = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 576px) {
    &:nth-child(2),
    &:nth-child(3) {
      .card-icon {
        .card-circle {
          background: #ff9c9c;
          background: -moz-linear-gradient(top, #ff9c9c 0%, #f60101 100%);
          background: -webkit-linear-gradient(top, #ff9c9c 0%, #f60101 100%);
          background: linear-gradient(to bottom, #ff9c9c 0%, #f60101 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9c9c', endColorstr='#f60101',GradientType=0 );
          box-shadow: 0 6px 12px rgba(254, 49, 49, 0.32);
        }
      }
    }
  }
`;
const CardIcon = styled.div`
  position: relative;
  background: #eaeff7;
  background: -moz-linear-gradient(-45deg, #eaeff7 0%, #f9fbff 100%);
  background: -webkit-linear-gradient(-45deg, #eaeff7 0%, #f9fbff 100%);
  background: linear-gradient(135deg, #eaeff7 0%, #f9fbff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaeff7', endColorstr='#f9fbff',GradientType=1 );
  border-radius: 15px;
  margin-bottom: 30px;
  @media (min-width: 576px) {
    margin-bottom: 50px;
    height: calc(100% - 50px);
  }
  @media (max-width: 575.98px) {
    display: flex;
    align-items: center;
  }
`;
const CardCircle = styled.div`
  border-radius: 50%;
  background: #64e4f3;
  background: -moz-linear-gradient(top, #64e4f3 0%, #278aff 100%);
  background: -webkit-linear-gradient(top, #64e4f3 0%, #278aff 100%);
  background: linear-gradient(to bottom, #64e4f3 0%, #278aff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#64e4f3', endColorstr='#278aff',GradientType=0 );
  box-shadow: 0 6px 12px rgba(49, 146, 254, 0.32);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 4px solid #fff;
  min-width: 48px;
  margin: 0 5px 0 15px;
  @media (min-width: 576px) {
    width: 68px;
    height: 68px;
    border: 7px solid #fff;
    position: absolute;
    bottom: calc(100% - 40px);
    left: 20px;
    margin: 0;
  }
  > svg {
    fill: #fff;
    transform-style: preserve-3d;
    transition: 0.5s ease all;
  }
  &:hover {
    > svg {
      transform: scale3d(1.3, 1.3, 1);
    }
  }
`;
const CardDescIcon = styled.div`
  padding: 20px 15px 20px 5px;
  @media (min-width: 576px) {
    padding: 60px 20px 15px 20px;
  }
  > p {
    margin-bottom: 0;
    @media (max-width: 575.98px) {
      line-height: 24px;
    }
  }
`;
const GridMasonery = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  justify-content: space-evenly;
  align-items: center;
`;
const GridItemMasonery = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 1200px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .card {
    .card-title {
      > span {
        @media (min-width: 1440px) {
          font-size: 18px;
        }
      }
    }
  }
`;
const SectionMasoneryImage = styled(SectionImageBkg)`
  background-color: #161420;
  border-radius: 0 0 100px 0;
  width: 100%;
  max-height: 200px;
  @media (min-width: 1200px) {
    max-height: 300px;
  }
`;

const CategoryTemplatePage = ({ data, location }) => {
  const pageData = data.contentfulCategory;
  const allCategories = data.allContentfulCategory.edges;
  const allProducts = data.allContentfulProduct.edges;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showLimit, setShowLimit] = useState(6);
  //const BreadcrumbSchema = getBreadcrumbSchema(pageData);
  //console.log(BreadcrumbSchema, 'Breadcrumb Schema tag');

  const HandleModalClose = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (isModalVisible) {
      document.querySelector("body").classList.add("modal-open");
    } else {
      document.querySelector("body").classList.remove("modal-open");
    }
  }, [isModalVisible, setIsModalVisible]);

  return (
    <Layout location={location}>
      <Seo
        title={pageData.metaTitle}
        description={pageData.metaDescription}
        location={location}
      />
      <Section bgColor="#fff">
        <Banners className="banner">
          <BannerfulLeft>
            <BreadCrumb className="black">
              <Link to="/">Home</Link>
              <span>{pageData.name}</span>
            </BreadCrumb>
            <BannerTitle>
              <strong>TX</strong> <span>{pageData.name}</span>
            </BannerTitle>
            <BannerLead>{pageData.subTitle}</BannerLead>
            <BannerDesc>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.description.childMarkdownRemark.html,
                }}
              />
              <ExploreMore mt="30px" justifyContent="left">
                <a href="tel:8067314815" aria-label="Phone Number">
                  <PrimaryButton
                    text="(806) 731-4815"
                    iconAfter={<PhoneIcon />}
                  />
                </a>
              </ExploreMore>
            </BannerDesc>
          </BannerfulLeft>
          <BannerfulRight>
            <GatsbyImage
              image={pageData.mainImage.gatsbyImageData}
              alt={pageData.name}
            />
          </BannerfulRight>
        </Banners>
      </Section>

      <Section
        ept="120px"
        epb="120px"
        xpt="80px"
        xpb="80px"
        pt="40px"
        pb="40px"
        bgColor="#fff"
        className="section-building"
      >
        <SectionMasoneryImage opacityImg="0.5">
          <StaticImage
            src="../images/outline-building.png"
            alt="outline-building"
          />
        </SectionMasoneryImage>
        <Container maxWidth="1640px">
          <SectionTitles textAlign="center" color="#fff">Our Top Selling <span className="stroke-white">{pageData.productListTitle}</span></SectionTitles>
          <GridMasonery>
            {allProducts.map(
              (item, i) =>
                i < showLimit && (
                  <GridItemMasonery className="item" key={i}>
                    <ProductCard sku={item.node.skuNumber} data={item.node} />
                  </GridItemMasonery>
                )
            )}
          </GridMasonery>
          {allProducts.length > showLimit && (
            <ExploreMore mt="30px">
              <button
                type="button"
                aria-label="button"
                onClick={() => setShowLimit(showLimit + 6)}
              >
                <PrimaryButton text="Load More" />
              </button>
            </ExploreMore>
          )}
        </Container>
      </Section>

      <Section
        ept="0"
        epb="120px"
        xpt="0"
        xpb="80px"
        pt="0"
        pb="40px"
        bgColor="#fff"
      >
        <Features className="features">
          <FeaturesLeft>
            <SectionSubTitle mb="0" className="h2">
              <span>Features of</span>
            </SectionSubTitle>
            <SectionTitle mb="40px">Metal {pageData.name}</SectionTitle>
            <GridIcon>
              {pageData.features.map((item, i) => (
                <GridItemIcon key={i}>
                  <CardIcon className="card-icon">
                    <CardCircle className="card-circle">
                      <CheckIcon />
                    </CardCircle>
                    <CardDescIcon>
                      <p>{item.content}</p>
                    </CardDescIcon>
                  </CardIcon>
                </GridItemIcon>
              ))}
            </GridIcon>
          </FeaturesLeft>
          <FeaturesRight>
            <SectionFeaturesImage opacityImg="0.5">
              <StaticImage
                src="../images/outline-building.png"
                alt="outline-building"
              />
            </SectionFeaturesImage>
            <GatsbyImage
              image={pageData.featureImage.gatsbyImageData}
              alt="featureImage"
            />
            {/* <StaticImage src="../images/utility-combo.png" alt="utility-combo" /> */}
          </FeaturesRight>
        </Features>
      </Section>

      <Sectionsize
        ept="120px"
        epb="0"
        xpt="80px"
        xpb="0"
        pt="40px"
        pb="0"
        bgColor="transparent"
        bgBefore="#161420"
        bgBeforeWidth="100%"
        bgBeforeHeight="80%"
        className="section-size"
      >
        <SectionVectorImage>
          <StaticImage
            src="../images/category-squre-pattern.png"
            alt="category-squre-pattern"
          />
        </SectionVectorImage>
        <Container className="container">
          <MarkdownContent maxWidth="1020px">
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.content.childMarkdownRemark.html,
              }}
            />
          </MarkdownContent>
        </Container>
      </Sectionsize>

      <Section
        ept="0"
        epb="80px"
        xpt="0"
        xpb="60px"
        pt="0"
        pb="30px"
        bgColor="transparent"
        bgBefore="linear-gradient(to bottom,  #FFFFFF 0%,#EAEFF7 100%)"
        bgBeforeWidth="100%"
        bgBeforeHeight="100%"
        className="section-color"
      >
        <Container>
          <ColorPicker />
        </Container>
      </Section>

      <Section
        ept="120px"
        epb="120px"
        xpt="80px"
        xpb="80px"
        pt="40px"
        pb="40px"
        bgColor="#fff"
        className="section-buy"
      >
        <SectionImageBkg height="50%" bgColor="#161420" opacityImg="0.3">
          <StaticImage src="../images/black-vector.jpg" alt="black-vector" />
        </SectionImageBkg>
        <CategoryCarousel data={allCategories} current={pageData.name} />
      </Section>
      <RequestModal isVisible={isModalVisible} onClose={HandleModalClose} />
    </Layout>
  );
};

export default CategoryTemplatePage;

export const pageQuery = graphql`
  query CategoryLandingQuery($id: String!, $name: String!) {
    contentfulCategory(id: { eq: $id }) {
      metaTitle
      metaDescription
      name
      url
      subTitle
      description {
        childMarkdownRemark {
          html
        }
      }
      productListTitle
      name
      mainImage {
        gatsbyImageData(placeholder: BLURRED, quality: 90)
      }
      features {
        content
      }
      featureImage {
        gatsbyImageData(placeholder: BLURRED, quality: 90)
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulCategory {
      edges {
        node {
          name
          url
          mainImage {
            gatsbyImageData(placeholder: BLURRED, quality: 90)
          }
        }
      }
    }
    allContentfulProduct(
      sort: { order: DESC, fields: skuNumber }
      filter: { category: { name: { eq: $name } } }
    ) {
      edges {
        node {
          price
          name
          url
          skuNumber
          image {
            gatsbyImageData(placeholder: BLURRED, quality: 90)
          }
          category {
            name
          }
        }
      }
    }
  }
`;
